<template>
    <div class="flex flex-auto flex-column" >
        <div class="w-12 mb-4 mr-4 flex-column">
            <div class="text-2xl font-bold">
                <ProgressSpinner v-if="isSearchRunning" style="width:1em;height:1em" />
                Suchergebnisse für: "{{searchQuery}}"
            </div>
            <div v-if="false">
            <Chip :label="'Meldungen ('+totalFoundFeedsCount + ')'"
                class="mx-2 my-4 px-3 py-1 shadow-1 surface-0" />

            <Chip :label="'Installation (' + foundSensorsCount + ')'"
                class="mx-2 my-4 px-3 py-1 shadow-1 surface-0" />
            </div>
        </div>

        <div class="flex flex-auto" >
            <div class="w-6 mr-4 flex flex-auto flex-column content-area">
                <div class="mb-2 text-xl font-bold">Meldungen <span class="text-primary">({{totalFoundFeedsCount}})</span></div>
                <div class="scrollable-flex pl-2 pt-2">
                    
                    <issue-list-item v-for="(feed) in foundFeeds" 
                        class="issue-list-item p-3 ml-0 mr-2 mb-3 cursor-pointer search-result-item"
                        :key="feed.pkey" 
                        :issue="feed"
                        @click="onClickFeed(feed)">
                    </issue-list-item>

                    <div v-if="hasFeedHistoryMatches" class="mt-3">
                        <div class="font-bold mb-2">Meldungen mit Treffern in der Historie und den Kommentaren</div>
                        <issue-list-item v-for="(feed) in foundFeedHistoryMatches" 
                            class="issue-list-item p-3 ml-0 mr-2 mb-3 cursor-pointer search-result-item"
                            :key="feed.pkey" 
                            :issue="feed"
                            @click="onClickFeed(feed)">
                        </issue-list-item>
                    </div>

                </div>
            </div>
            
            <div class="w-6 mr-4 flex flex-auto flex-column content-area">
                <div class="mb-2 text-xl font-bold">Installation <span class="text-primary">{{foundSensorsCount}}</span></div>
                <div class="scrollable-flex pl-2 pt-2">
                    <sensor-list-item v-for="(a) in foundSensors" 
                        class="p-3 ml-0 mr-2 mb-4 cursor-pointer search-result-item"
                        :key="a.pkey" 
                        :sensor="a" @click="onClickSensor(a)">
                    </sensor-list-item>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import { /*ref, */ computed, onMounted, watch } from  "vue";
import { useStore } from "vuex";
import ProgressSpinner from 'primevue/progressspinner';
import IssueListItem from './IssueListItem.vue';
import SensorListItem from './SensorListItem.vue';
import { useRouter } from "vue-router";

export default ({
    name: "SearchPage",
    components: { ProgressSpinner, IssueListItem, SensorListItem },
    props: {
        query: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        
        let m_componentMounted = false;
        let m_componentInitialized = false;
        
        onMounted(() => {
            m_componentMounted = true;
            initWhenReady();
        });

        watch(() => store.getters.isready, function () { initWhenReady(); });
        //watch(() => props.displaysensorkey, () => { selectSensorByPkey(props.displaysensorkey); });

        const initWhenReady = async () => {
            if (m_componentInitialized || !m_componentMounted || !store.getters.isready) return;
            m_componentInitialized = true;

            // if there is no query in the store to be displayed, we've got a page reload, re-do the search
            if (store.getters.search.searchquery.length == 0) {
                store.dispatch("search", props.query);
            }
        }

        const isSearchRunning = computed( () => { 
            return store.getters.search.searchrunning;
        });

        const searchQuery = computed( () => { 
            return store.getters.search.searchquery;
        });

        const foundFeeds = computed( () => { 
            return store.getters.search.foundfeeds;
        });

        const foundFeedHistoryMatches = computed( () => {
            return store.getters.search.foundfeedsinhistory;
        });

        const totalFoundFeedsCount = computed( () => { 
            return (store.getters.search.foundfeeds.length + store.getters.search.foundfeedsinhistory.length);
        });

        const hasFeedHistoryMatches = computed( () => { 
            return (store.getters.search.foundfeedsinhistory.length > 0);
        });

        const foundSensors = computed( () => { 
            return store.getters.search.foundsensors;
        });

        const foundSensorsCount = computed( () => { 
            return store.getters.search.foundsensors.length;
        });


        const onClickFeed = (feed) => {
            router.push({name:"feed", params: {displayfeedkey: feed.pkey} });
        }

        const onClickSensor = (sensor) => {
            router.push({name:"sensor", params: {displaysensorkey: sensor.pkey} });
        }

        return {isSearchRunning,
                searchQuery,
                foundFeeds,
                foundFeedHistoryMatches,
                totalFoundFeedsCount,
                hasFeedHistoryMatches,
                foundSensors,
                foundSensorsCount,
                onClickFeed, onClickSensor
         };
    },
})
</script>

<style scoped>

.search-result-item {
    background-color: var(--surface-b);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.03), 
                0px 0px 2px rgba(0, 0, 0, 0.06), 
                1px -1px 4px rgba(0, 0, 0, 0.10), 
                0px 2px 6px rgba(0, 0, 0, 0.12); 
}

/*
.search-result-item:hover {
    box-shadow: 0px 4px 6px rgba(121, 110, 216, 0.2), 
                0px 0px 2px rgba(121, 110, 216, 0.60),
                2px 1px 4px rgba(121, 110, 216, 0.60), 
                0px 2px 6px rgba(121, 110, 216, 0.6); 
}*/

</style>

